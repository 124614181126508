import { useCallback, useState } from "react";
import "./styles.css";

const api = "https://api.unpaywall.org/v2";
const email = "eaton.alf@gmail.com";

const search = async (query) => {
  const url = new URL("/search", api);
  url.searchParams.set("query", query);
  url.searchParams.set("email", email);
  url.searchParams.set("is_oa", "true");

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error();
  }

  const { results } = await response.json();

  if (!results || !results.length) {
    return null;
  }

  const [result] = results;

  return result.response;
};

const retrieve = async (doi) => {
  const params = new URLSearchParams({ email });

  const url = `${api}/${encodeURIComponent(doi)}?${params}`;

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error();
  }

  return response.json();
};

const lookup = (query) => {
  return /^10\./.test(query) ? retrieve(query) : search(query);
};

export default function App() {
  const [item, setItem] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const fetchData = useCallback(async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    let query = data.get("query").trim();

    if (!query) {
      return;
    }

    setError(undefined);
    setItem(undefined);
    setLoading(true);

    try {
      const item = await lookup(query);

      if (item) {
        setItem(item);

        if (item.best_oa_location.url) {
          setTimeout(() => {
            window.open(item.best_oa_location.url);
          }, 0);
        }
      } else {
        setError("Couldn't find anything!");
      }
    } catch {
      setError("Something went wrong!");
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <div style={{ width: 600, maxWidth: "100%", padding: 10 }}>
      <h1 style={{ textTransform: "uppercase", color: "#933" }}>SKIPPY</h1>
      <p style={{ padding: 10, background: "#933", color: "white" }}>
        … find the article you need to read
      </p>
      <form
        onSubmit={fetchData}
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          background: "rgba(100, 100, 100, 0.2)",
          marginBottom: 20,
          padding: 10,
          gap: 10
        }}
      >
        <div style={{ flex: 1 }}>
          <input
            autoFocus
            name="query"
            placeholder="enter DOI or search string"
            size={40}
            style={{
              padding: 4,
              fontSize: 15,
              textAlign: "center",
              width: "100%",
              boxSizing: "border-box"
            }}
          />
        </div>
        <button
          type="submit"
          style={{
            flexShrink: 0,
            padding: "15px 20px",
            fontSize: 20,
            background: "#933",
            color: "white",
            border: "none",
            cursor: "pointer"
          }}
          disabled={loading}
        >
          <span role="img" aria-label="key">
            🔑
          </span>{" "}
          open
        </button>
      </form>

      {error && <div>{error}</div>}

      {item && (
        <div className="card">
          {item.best_oa_location && (
            <a
              href={item.best_oa_location.url}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div style={{ fontWeight: 900 }}>{item.title}</div>
              <div>{new URL(item.best_oa_location.url).host}</div>
              <div>
                {item.year} &middot; {item.doi}
              </div>
            </a>
          )}
        </div>
      )}
    </div>
  );
}
